import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CotroliaFormControl from "../../core/input/CotroliaFormControl";
import CotroliaInputSkeleton from "../../core/skeleton/CotroliaInputSkeleton";
import CotroliaTextareaSkeleton from "../../core/skeleton/CotroliaTextareaSkeleton";
import CotroliaTextField from "../../core/input/CotroliaTextField";

const useStyle = makeStyles((theme) => ({
  descriptionContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& .MuiSkeleton-root, .MuiFormControl-root": {
      flexBasis: "33%",
      [theme.breakpoints.down("md")]: {
        flexBasis: "49%",
      },
      [theme.breakpoints.down("sm")]: {
        flexBasis: "100%",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "&:after": {
        content: "''",
        flexBasis: "33%",
      },
    },
  },
}));

/**
 * Content of the description section
 *
 * @param   {Object}  intervention     Intervention sent by the api
 * @returns                            Component content
 */
const InterventionCarDetailDescription = ({ intervention }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Box>
      <Box className={classes.descriptionContainer}>
        {/* Frame */}
        {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("Frame")}
              value={intervention.frame ? intervention.frame : ""}
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}

        {/* Car type */}
        {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("model")}
              value={
                intervention.carType && intervention.carType.label 
                  ? intervention.carType.label
                  : ""
              }
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}

        {/* Distance */}
        {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("Distance")}
              value={intervention.distance ? intervention.distance : ""}
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}

        {/* Registration */}
        {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("Registration")}
              value={intervention.registration ? intervention.registration : ""}
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}

        {/* Security Code */}
        {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("SecurityCode")}
              value={intervention.security_code ? intervention.security_code : ""}
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )}

        {/* Year */}
        {/* {intervention ? (
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("Year")}
              value={intervention.year ? intervention.year : ""}
              disabled
            />
          </CotroliaFormControl>
        ) : (
          <CotroliaInputSkeleton />
        )} */}
      </Box>
      {/* Customer Effect */}
      {intervention ? (
        <CotroliaFormControl>
          <CotroliaTextField
            label={t("CarCustomerEffect")}
            value={intervention.customer_effect ? intervention.customer_effect : ""}
            disabled
            multiline
            rows={4}
          />
        </CotroliaFormControl>
      ) : (
        <CotroliaTextareaSkeleton />
      )}

      {/* Diagnostic */}
      {intervention ? (
        <CotroliaFormControl>
          <CotroliaTextField
            label={t("Diagnostic")}
          value={intervention.diagnostic ? intervention.diagnostic : ""}
            disabled
            multiline
            rows={4}
          />
        </CotroliaFormControl>
      ) : (
        <CotroliaTextareaSkeleton />
      )}

      {/* Parts Replaced */}
      {intervention ? (
        <CotroliaFormControl>
          <CotroliaTextField
            label={t("PartsReplaced")}
          value={intervention.parts_replaced ? intervention.parts_replaced : ""}
            disabled
            multiline
            rows={4}
          />
        </CotroliaFormControl>
      ) : (
        <CotroliaTextareaSkeleton />
      )}
    </Box>
  );
};

export default InterventionCarDetailDescription;
