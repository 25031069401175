// MatomoTracker.js
import React, { useEffect } from 'react';
import { initMatomo, trackPageView } from 'matomo-react-tracker';

const MatomoTracker = ({ matomoUrl, children }) => {
  useEffect(() => {
    // Determine the site ID based on your logic (e.g., check URL, etc.)
    const hostname = window.location.hostname;
    const siteId = hostname.includes('app.repturn.com') ? '9' : '10';

    // Initialize Matomo
    initMatomo(matomoUrl, siteId);

    // Track initial page view
    trackPageView();
  }, [matomoUrl]);

  return <>{children}</>;
};

export default MatomoTracker;