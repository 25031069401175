import {
  Box,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CotroliaTextField from "./CotroliaTextField";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
  suggestionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxHeight: "250px",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    overflowY: "scroll",
  },
  entryContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      cursor: "pointer",
    },
  },
  entryLabel: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
}));

const SuggestionEntry = ({
  value,
  setValue,
  clearSuggestion,
  isSuggestionObject,
  labelKey,
}) => {
  const classes = useStyle();

  const handleClick = (newValue) => {
    setValue(newValue);
    clearSuggestion();
  };

  return (
    <Box className={classes.entryContainer} onClick={() => handleClick(value)}>
      <Typography variant="body2" className={classes.entryLabel}>
        {isSuggestionObject ? value[labelKey] : value}
      </Typography>
    </Box>
  );
};

const CotroliaAutocomplete = ({
  setValue,
  getSuggestions,
  isSuggestionObject,
  labelKey = "",
  ...props
}) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const [search, setSearch] = useState("");
  const [noRequest, setNoRequest] = useState(true);
  const [defaultValue, setDefaultValue] = useState(props.value);

  useEffect(() => {
    if (!noRequest) {
      setValue(search);

      // Delay search to avoid too many requests
      const delayDebounceFn = setTimeout(() => {
        setLoading(true);
        getSuggestions(search)
          .then((res) => {
            setLoading(false);
            setSuggestions(res);
          })
          .catch((err) => {
            setLoading(false);
          });
      }, 100);

      return () => clearTimeout(delayDebounceFn);
    }

    if (noRequest) {
      setNoRequest(false);
    }
  }, [search]);

  const resetSearch = () => {
    isSuggestionObject
      ? setSearch(props.value[labelKey])
      : setSearch(props.value);
    if (noRequest) {
      setNoRequest(false);
    }
  };

  useEffect(() => {
    setNoRequest(true);
    isSuggestionObject
      ? setSearch(defaultValue[labelKey])
      : setSearch(defaultValue);
  }, [defaultValue, isSuggestionObject, labelKey]);

  useEffect(() => {
    setDefaultValue(props.value);
  }, [props.value]);

  const setValueFromSuggestion = (suggestion) => {
    setNoRequest(true);
    if (isSuggestionObject) {
      setSearch(suggestion[labelKey]);
    } else {
      setSearch(suggestion);
    }
    setValue(suggestion);
  };

  return (
    <>
      <CotroliaTextField
        {...props}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onFocus={() => {
          resetSearch();
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{<SearchIcon />}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {loading && <CircularProgress color="primary" size={24} />}
            </InputAdornment>
          ),
        }}
      >
        {props.children}
      </CotroliaTextField>
      <Box className={classes.suggestionContainer}>
        {suggestions &&
          Object.keys(suggestions).map((key) => (
            <SuggestionEntry
              key={key}
              value={suggestions[key]}
              setValue={setValueFromSuggestion}
              clearSuggestion={() => setSuggestions(null)}
              isSuggestionObject={isSuggestionObject}
              labelKey={labelKey}
            />
          ))}
      </Box>
    </>
  );
};

export default CotroliaAutocomplete;
